export const TryHowItWorks = () => {
  const form = document.querySelector(".try-how form");
  form.querySelector(".button__link").addEventListener("click", () => {
    const isFormValid = form.checkValidity();
    if (isFormValid) {
      const formObject = Object.fromEntries(new FormData(form).entries());
      const phoneInput = window.intlTelInputGlobals.getInstance(
        form.querySelector("#phone")
      );
      formObject.phoneNumber = phoneInput.getNumber(1);
      formObject.originUrl = "feedback.bizplatform.co";

      const http = new XMLHttpRequest();
      const url = "https://feedback-service.bizplatform.co/api/contacts";
      http.open("POST", url);
      http.setRequestHeader("Content-Type", "application/json");
      http.send(JSON.stringify(formObject));

      http.onreadystatechange = function () {
        if (this.status === 200)
          document.querySelector(".try-how__result").style.visibility =
            "visible";
      };
    }
    return false;
  });
};
