export const Header = () => {
  const lang = document.querySelector(".header__lang-current");
  lang.addEventListener("click", () => {
    if (lang.classList.contains("header__lang-current--close")) {
      lang.classList.remove("header__lang-current--close");
      lang.classList.add("header__lang-current--open");
    } else {
      lang.classList.remove("header__lang-current--open");
      lang.classList.add("header__lang-current--close");
    }
  });
};
