import intlTelInput from "intl-tel-input";
import telUtils from "intl-tel-input/build/js/utils";
import IMask from "imask";

export const mask = () => {
  const input = document.querySelector("#phone");
  const maskOptions = {
    mask: "000 000 00 00",
  };
  const mask = IMask(input, maskOptions);
};
export const inputPhone = () => {
  let input = document.querySelector("#phone");

  const geoIpLookup = (callback) => {
    const http = new XMLHttpRequest();
    const url = "https://ipinfo.io/json?token=68fbccef5734a9";
    http.responseType = "json";
    http.open("GET", url, true);
    http.onload = function () {
      callback(http.response.country);
    };
    http.send();
  };

  intlTelInput(input, {
    //onlyCountries: ["ru", "us"],
    initialCountry: "auto",
    geoIpLookup,
    utilsScript: telUtils,
    formatOnDisplay: true,
  });
};
