import "./ru.scss";

import { Header } from "../../components/Header/Header";
import { TryHowItWorks } from "../../components/TryHowItWorks/TryHowItWorks";
import { inputPhone } from "../../components/Form/Form";

import SmoothScroll from "smooth-scroll";

document.addEventListener("DOMContentLoaded", () => {
  Header();
  inputPhone();
  TryHowItWorks();
  const scroll = new SmoothScroll('a[href*="#"]');
});
